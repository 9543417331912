var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-card",
        {
          staticClass:
            "d-flex justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4",
          attrs: { flat: "" },
        },
        [
          _c("span", { staticClass: "f15" }, [
            _vm._v(_vm._s(_vm.$t("Amount"))),
          ]),
          _c("span", { staticClass: "primary--text f14" }, [
            _vm._v(
              _vm._s(_vm._f("VMask")(_vm.item.totalCash, _vm.currencyMask)) +
                " " +
                _vm._s(_vm.$t("RIAL"))
            ),
          ]),
        ]
      ),
      _c(
        "v-card",
        {
          staticClass:
            "d-flex justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4",
          attrs: { flat: "" },
        },
        [
          _c("span", { staticClass: "f15" }, [
            _vm._v(_vm._s(_vm.$t("Issue tracking"))),
          ]),
          _c("span", { staticClass: "primary--text f14" }, [
            _vm._v(_vm._s(_vm.item.orderId)),
          ]),
        ]
      ),
      _c(
        "v-card",
        {
          staticClass:
            "d-flex justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4",
          attrs: { flat: "" },
        },
        [
          _c("span", { staticClass: "f15" }, [
            _vm._v(_vm._s(_vm.$t("Deposit date"))),
          ]),
          _c(
            "span",
            {
              staticClass:
                "f14 primary--text d-flex flex-row align-items-center",
            },
            [
              _c("i", { staticClass: "far fa-calendar-alt ms-0 me-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moment")(
                      _vm.item.createDate,
                      "dddd , jD jMMMM jYYYY"
                    )
                  ) +
                  " "
              ),
              _c("i", { staticClass: "far fa-clock ms-2 me-1" }),
              _vm._v(
                " " +
                  _vm._s(_vm._f("moment")(_vm.item.createDate, "HH:MM")) +
                  " "
              ),
            ]
          ),
        ]
      ),
      _c(
        "v-card",
        {
          staticClass:
            "d-flex justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4",
          attrs: { flat: "" },
        },
        [
          _c("span", { staticClass: "f15" }, [_vm._v(_vm._s(_vm.$t("IBAN")))]),
          _c("span", { staticClass: "primary--text f14" }, [
            _vm._v(_vm._s(_vm.item.iban)),
          ]),
        ]
      ),
      _c(
        "v-card",
        {
          staticClass:
            "d-flex flex-column justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4",
          attrs: { flat: "" },
        },
        [
          _c("span", { staticClass: "f15" }, [
            _vm._v(_vm._s(_vm.$t("Description"))),
          ]),
          _vm.item.description
            ? _c("p", {
                staticClass: "primary--text f15 px-2 mb-0",
                domProps: { innerHTML: _vm._s(_vm.item.description) },
              })
            : _c("p", { staticClass: "primary--text f14 px-2 mb-0" }, [
                _vm._v(_vm._s(_vm.$t("No description"))),
              ]),
        ]
      ),
      _c("span", { staticClass: "f16 fw-bold" }, [
        _vm._v(_vm._s(_vm.$t("Transactions"))),
      ]),
      _vm.transactions && _vm.transactions.length > 0
        ? _c(
            "div",
            _vm._l(_vm.transactions, function (item, index) {
              return _c(
                "v-card",
                {
                  key: index,
                  staticClass: "my-2",
                  attrs: { flat: "", hover: "", ripple: "", color: "white" },
                },
                [
                  _c(
                    "b-list-group-item",
                    { staticClass: "d-flex align-items-center rounded-xl" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column ms-4 me-0 align-items-start justify-content-center",
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            item.reason == "CHARGE_WALLET"
                              ? _c("span", { staticClass: "f14" }, [
                                  _vm._v(_vm._s(_vm.$t("Wallet charge"))),
                                ])
                              : _vm._e(),
                            item.reason == "EXAM"
                              ? _c("span", { staticClass: "f14" }, [
                                  _vm._v(_vm._s(item.clientFullName)),
                                ])
                              : _vm._e(),
                            item.reason == "EXAM"
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "f12 ms-2 me-0 primary lighten-5 rounded-pill px-3 py-1 primary--text",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Exam")))]
                                )
                              : _vm._e(),
                            item.reason == "CONSULTANT"
                              ? _c("span", { staticClass: "f14" }, [
                                  _vm._v(_vm._s(item.clientFullName)),
                                ])
                              : _vm._e(),
                            item.reason == "CONSULTANT"
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "f12 ms-2 me-0 primary lighten-5 rounded-pill px-3 py-1 primary--text",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Consultant")))]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "f12 grey--text d-flex flex-row align-items-center",
                            },
                            [
                              _c("i", {
                                staticClass: "far fa-calendar-alt ms-0 me-1",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      item.createDate,
                                      "dddd , jD jMMMM jYYYY"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("i", {
                                staticClass: "far fa-clock ms-2 me-1",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(item.createDate, "HH:MM")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex ms-auto me-0 align-items-end justify-content-center flex-column",
                        },
                        [
                          item.status == "WITHDRAW"
                            ? _c("span", { staticClass: "f14 red--text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("VMask")(
                                      item.amount,
                                      _vm.currencyMask
                                    )
                                  ) +
                                    " " +
                                    _vm._s(_vm.$t("RIAL"))
                                ),
                              ])
                            : _vm._e(),
                          item.status == "DEPOSIT"
                            ? _c("span", { staticClass: "f14 green--text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("VMask")(
                                      item.amount,
                                      _vm.currencyMask
                                    )
                                  ) +
                                    " " +
                                    _vm._s(_vm.$t("RIAL"))
                                ),
                              ])
                            : _vm._e(),
                          item.status == "DEPOSIT" && item.isSettled == true
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "f10 green--text green lighten-5 rounded-pill px-2",
                                },
                                [_vm._v(_vm._s(_vm.$t("Settled")))]
                              )
                            : _vm._e(),
                          item.status == "DEPOSIT" &&
                          item.isSettled == false &&
                          item.settlementId
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "f10 green--text green lighten-5 rounded-pill px-2",
                                },
                                [_vm._v(_vm._s(_vm.$t("Awaiting settlement")))]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
            _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }